import { FC } from "react";
import styles from "./styles.module.scss";
import { LockFilled } from "@mollie/ui-assets/icons/utilitarian";
import { Dark, Light } from "@mollie/ui-assets/mollieBrand/mollieLogo";
import { Box, Icon, Text } from "@mollie/ui-react";
import { getPreferredColorScheme } from "@mollie/revenue-collection-utils";

export const Footer: FC = () => {
  return (
    <Box>
      <Text
        className={styles.poweredBy}
        variant="caption-regular"
        fontSize="font-size-75"
        textAlign="center"
        color="secondary"
      >
        <Icon
          src={LockFilled}
          width="1em"
          height="1em"
          marginBlockStart="-0.20em"
          marginInlineEnd="space-25"
        />{" "}
        Powered by{" "}
        <Icon
          src={getPreferredColorScheme() === "dark" ? Light : Dark}
          marginBlockStart="-0.3em"
          size="large"
        />
      </Text>
    </Box>
  );
};

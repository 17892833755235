import { FC } from "react";
import { StateWithFooter } from "app/components";
import { messages } from "app/i18n";
import { useIntl } from "react-intl";

export const UnexpectedErrorState: FC = () => {
  const intl = useIntl();
  return (
    <StateWithFooter
      title={intl.formatMessage(messages.paymentLinkUnexpectedError)}
      variant="error"
    />
  );
};

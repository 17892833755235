export function extractCurrencyAndAmount(input: string): {
  currency: string;
  amount: string;
} {
  const currencyRegex = /^[A-Z]{3}/;
  const amountRegex = /[0-9]+(?:,[0-9]{1,3})?$/;

  const currencyMatch = input.match(currencyRegex);
  const amountMatch = input.match(amountRegex);

  const currency = currencyMatch ? currencyMatch[0] : "EUR";
  const amount = amountMatch ? amountMatch[0].replace(",", ".") : "";

  return { currency, amount };
}

import { messagesDe, messagesEnGb, messagesEnUs, messagesEsEs, messagesFr, messagesIt, messagesNlBe, messagesNl } from ".";

export const appLanguages = ["en", "uk", "de", "fr", "nl", "be", "es", "it"] as const;
export type AppLanguage = (typeof appLanguages)[number];
export type Locale = "en" | "en-GB" | "de" | "fr-FR" | "nl-NL" | "nl-BE" | "es-ES" | "it-IT";


export const isValidLanguage = (x: string): x is AppLanguage =>
  appLanguages.includes(x as AppLanguage);

export function getLocaleFromAppLanguage(language: AppLanguage): Locale {
  switch (language) {
    case "uk":
      return "en-GB";
    case "de":
      return "de";
    case "fr":
      return "fr-FR";
    case "nl":
      return "nl-NL";
    case "be":
      return "nl-BE";
    case "es":
      return "es-ES";
    case "it":
      return "it-IT";
    default:
      return "en";
  }
}

export function getAppLanguageFromLocale(locale: Locale): AppLanguage {
  switch (locale) {
    case "en-GB":
      return "uk";
    case "de":
      return "de";
    case "fr-FR":
      return "fr";
    case "nl-NL":
      return "nl";
    case "nl-BE":
      return "be";
    case "es-ES":
      return "es";
    case "it-IT":
      return "it";
    default:
      return "en";
  }
}

interface LocaleData {
  locale: Locale;
  appLanguage: AppLanguage;
  label: string;
  messages: Record<string, string>;
}

export const intlData: LocaleData[] = [
  {
    locale: "en",
    appLanguage: "en",
    label: "English",
    messages: messagesEnUs,
  },
  {
    locale: "en-GB",
    appLanguage: "uk",
    label: "English GB",
    messages: messagesEnGb,
  },
  {
    locale: "de",
    appLanguage: "de",
    label: "Deutsch",
    messages: messagesDe,
  },
  {
    locale: "fr-FR",
    appLanguage: "fr",
    label: "Français",
    messages: messagesFr,
  },
  {
    locale: "nl-NL",
    appLanguage: "nl",
    label: "Nederlands",
    messages: messagesNl,
  },
  {
    locale: "nl-BE",
    appLanguage: "be",
    label: "Nederlands BE",
    messages: messagesNlBe,
  },
  {
    locale: "es-ES",
    appLanguage: "es",
    label: "Español",
    messages: messagesEsEs,
  },
  {
    locale: "it-IT",
    appLanguage: "it",
    label: "Italiano",
    messages: messagesIt,
  },
];

interface IntlProviderProps {
  locale: Locale;
  messages: LocaleData["messages"];
}

export function getIntlProviderProps(locale: Locale): IntlProviderProps {
  const byLocale = (option: LocaleData): boolean => option.locale === locale;
  const filteredOptions = intlData.filter(byLocale);
  let messages: LocaleData["messages"] = messagesEnUs;
  if (filteredOptions.length > 0) {
    locale = filteredOptions[0].locale;
    messages = filteredOptions[0].messages;
  }
  return { locale, messages };
}

const browserLanguage = navigator.language.split(/[-_]/)[0];

export function defaultAppLanguage(): AppLanguage {
  if (appLanguages.includes(browserLanguage as AppLanguage)) {
    return browserLanguage as AppLanguage;
  }

  return "en";
}

export function getAppLanguageFromPathname(pathname: string): AppLanguage {
  const parsedAppLanguage = pathname.split("/")[1];
  if (appLanguages.includes(parsedAppLanguage as AppLanguage)) {
    return pathname.split("/")[1] as AppLanguage;
  }
  return "en";
}

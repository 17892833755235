import { defineMessages } from "react-intl";

export const messages = defineMessages({
  paymentLinkLandingLoadingTitle: {
    id: "payment-links.payment-link-landing-loading-title",
    defaultMessage: "Loading...",
  },
  paymentLinkLandingLoadingMessage: {
    id: "payment-links.payment-link-landing-loading-message",
    defaultMessage: "Your Payment Link payment options will appear soon.",
  },
  paymentLinkNotFound: {
    id: "payment-links.payment-link-not-found",
    defaultMessage: "Payment link not found",
  },
  paymentLinkExpired: {
    id: "payment-links.payment-link-expired",
    defaultMessage: "This payment link has expired",
  },
  paymentLinkUnexpectedError: {
    id: "payment-links.payment-link-unexpected-error",
    defaultMessage:
      "An unexpected error occurred, please try again later or contact support",
  },
  paymentLinkExpiredDescription: {
    id: "payment-links.payment-link-expired-description",
    defaultMessage: "Contact {profileName} to request a new link",
  },
  paymentLinkPaid: {
    id: "payment-links.payment-link-paid",
    defaultMessage: "This payment link has been paid",
  },
  paymentLinkPaymentLoadingMessage: {
    id: "payment-links.payment-link-payment-loading-message",
    defaultMessage: "Your payment status will appear soon.",
  },
  paymentLinkPaymentPaidTitle: {
    id: "payment-links.payment-link-payment-paid-title",
    defaultMessage: "Your {amount} payment to {profileName} succeeded",
  },
  paymentLinkPaymentPendingTitle: {
    id: "payment-links.payment-link-payment-pending-title",
    defaultMessage: "Your {amount} payment to {profileName} is pending",
  },
  paymentLinkPaymentPendingDescription: {
    id: "payment-links.payment-link-payment-pending-description",
    defaultMessage:
      "We’re awaiting your payment status. We’ll inform you once the payment has cleared.",
  },
  paymentLinkPaymentErrorNoSuitablePaymentMethods: {
    id: "payment-links.payment-link-payment-error-no-suitable-payment-methods",
    defaultMessage:
      "No suitable payment methods found for this payment link. Contact the supplier of your link to resolve this problem.",
  },

  // Variable amount form
  paymentLinkVariableAmountFormAmountLabel: {
    id: "payment-links.payment-link-variable-amount-form-amount-label",
    defaultMessage: "Amount",
  },
  paymentLinkVariableAmountFormCurrencyLabel: {
    id: "payment-links.payment-link-variable-amount-form-currency-label",
    defaultMessage: "Currency",
  },
  paymentLinkVariableAmountFormDescriptionLabel: {
    id: "payment-links.payment-link-variable-amount-form-description-label",
    defaultMessage: "Description",
  },
  paymentLinkVariableAmountFormDescriptionHelperText: {
    id: "payment-links.payment-link-variable-amount-form-description-helper-text",
    defaultMessage: "A note for the payment you’re sending",
  },
  paymentLinkVariableAmountFormPayNowButton: {
    id: "payment-links.payment-link-variable-amount-form-pay-now-button",
    defaultMessage: "Pay now",
  },
  paymentLinkVariableAmountFormErrorAmountPositive: {
    id: "payment-links.payment-link-variable-amount-form-error-amount-positive",
    defaultMessage: "Amount must be positive",
  },
  paymentLinkVariableAmountFormErrorPaymentAmountTooHigh: {
    id: "payment-links.payment-link-variable-amount-form-error-payment-amount-too-high",
    defaultMessage: "Amount is too high",
  },
  paymentLinkVariableAmountFormErrorPaymentAmountTooLow: {
    id: "payment-links.payment-link-variable-amount-form-error-payment-amount-too-low",
    defaultMessage: "Amount is too low",
  },
});

import { FC } from "react";
import { StateWithFooter } from "app/components";

type ExpectedErrorStateProps = {
  error: string;
};

export const ExpectedErrorState: FC<ExpectedErrorStateProps> = ({ error }) => {
  return <StateWithFooter title={error} variant="error" />;
};

import { Avatar, Box, Icon } from "@mollie/ui-react";
import { FC } from "react";
import * as paymentMethods from "@mollie/ui-assets/thirdParty/paymentMethod";
import { Check } from "@mollie/ui-assets/icons/utilitarian";
import styles from "./styles.module.scss";

type PaymentMethodIconProps = {
  methodName: string;
  schema?: string;
  fallbackIcon: string;
};

const methods = (
  Object.keys(paymentMethods) as (keyof typeof paymentMethods)[]
).map((paymentMethod) => ({
  icon: paymentMethods[paymentMethod],
  name: paymentMethod.toLowerCase(),
}));

export const PaymentMethodIcon: FC<PaymentMethodIconProps> = ({
  methodName,
  schema,
  fallbackIcon,
}) => {
  let methodIcon = methods.find(
    (method) => method.name === methodName.toLowerCase(),
  )?.icon;

  // For CB the Mollie UI naming is different
  if (schema === "Cartes_Bancaires") {
    schema = "CarteBancaire";
  }

  if (schema) {
    const schemaIcon = methods.find(
      (method) => method.name === schema.toLowerCase(),
    )?.icon;
    methodIcon = schemaIcon || methodIcon;
  }

  return (
    <>
      <Box marginInline="auto" position="relative" style={{ left: "8px" }}>
        <Avatar
          variant="payment"
          size="extra-large"
          src={!methodIcon ? fallbackIcon : undefined}
          marginInline="auto"
          marginBlockEnd="space-50"
        >
          {methodIcon && (
            <Icon data-testid={methodIcon.displayName} src={methodIcon} />
          )}
        </Avatar>
        <Avatar
          variant="action"
          backgroundColor="positive"
          width="16px"
          height="16px"
          className={styles.checkmark}
        >
          <Icon src={Check} width="8px" height="8px" />
        </Avatar>
      </Box>
    </>
  );
};

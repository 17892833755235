import { useLocation } from "react-router-dom";
import {
  AppLanguage,
  getAppLanguageFromPathname,
} from "@mollie/revenue-collection-intl";

function useAppLanguage(): AppLanguage {
  const { pathname } = useLocation();
  return getAppLanguageFromPathname(pathname);
}

export default useAppLanguage;
